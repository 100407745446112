<template>
  <div class="linelist-wrap">
    <div class="bread-wrap">
      <span class="bread-title">直播快</span>
    </div>
    <div class="content">
      <div class="list-top">
        <el-button type="primary" class="createline" @click="navtocreate">创建线路</el-button>
        <el-button type="primary" class="batchRenew" v-if="!subAccount" @click="batchrenew">批量续费</el-button>

        <el-button size="mini" type="primary" class="refreshList" @click="handleSearch">刷新</el-button>
        <el-button size="mini" type="primary" class="refreshList2" @click="settingClick">设置</el-button>
        <el-button class="filter-item" size="mini" type="primary" @click="handleSearch">
          搜索
        </el-button>
        <el-input class="search-input" v-model="query" size="mini" placeholder="支持按名称、ID、IP和业务组进行模糊搜索" style="width: 250px"
          @keyup.enter.native="handleSearch" clearable />
      </div>
      <div class="screen" v-if="isSearch">
        <div class="screen-title">
          共{{ this.tabledata.length }}条数据<el-divider direction="vertical"></el-divider>筛选条件：{{ this.query }}
        </div>
        <el-button size="mini" type="primary" class="clear" @click="refreshlist">清空</el-button>
      </div>
      <com-table class="linelist" :headercellstyle="headercellstyle" :columns="showTableColumn" :data="tabledata"
        :rowkey="(tabledata) => tabledata.id" tableheight="calc(100vh - 300px)" :pagination="pagination"
        @handleSelectionChange="handleSelectionChange" @cellmouseenter="cellmouseenter" @cellmouseleave="cellmouseleave">
        <el-table-column align="center" fixed="right" header-align="center" slot="action" label="操作" min-width="200">
          <template slot-scope="scope">
            <el-dropdown>
              <el-button class="sharebtn" size="mini" type="primary">分享</el-button>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item @click.native="showdownloadmodel(scope.row)">生成二维码</el-dropdown-item>
                  <el-dropdown-item @click.native="copyToken(scope.row)">复制链接</el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
            <el-button class="sharebtn" size="mini" type="primary" @click="showDetail(scope.row)">详情</el-button>
            <el-dropdown v-if="!subAccount">
              <el-button class="sharebtn" size="mini" type="primary">更多</el-button>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item @click.native="refreshconn(scope.row)">重置线路密钥</el-dropdown-item>
                  <el-dropdown-item @click.native="showAutoRenewModel(scope.row)">自动续费管理</el-dropdown-item>
                  <el-dropdown-item v-if="scope.row.isExistPermission" :disabled="scope.row.isInWhiteList" @click.native="disconnection(scope.row)">直播快初体验</el-dropdown-item>
                  <el-dropdown-item v-if="avaliableUpgradeMap.get(scope.row.productId)"
                    @click.native="showUpgradeModel(scope.row)">升级</el-dropdown-item>
                  <el-dropdown-item @click.native="pathtool(scope.row)">诊断</el-dropdown-item>
                  <el-dropdown-item @click.native="gorenew(scope.row)">续费</el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </template>
        </el-table-column>
      </com-table>
        <el-drawer title="我是标题" :visible.sync="drawer" :with-header="false" size="600px">
        <br />
        <div style="font-size: 22px">基本信息</div>
        <br />
        <div class="detail">
          <span class="dtcontent">名称</span><span class="dttext">{{ currentRow.name }}</span>
        </div>
        <el-divider></el-divider>
        <div class="detail">
          <span class="dtcontent">资源ID</span><span class="dttext">{{ currentRow.id }}</span>
        </div>
        <el-divider></el-divider>
        <div class="detail">
          <span class="dtcontent">规格</span><span class="dttext">{{
            productType(currentRow.productId)
          }}</span>
        </div>
        <el-divider></el-divider>
        <div class="detail">
          <span class="dtcontent">加速区域</span><span class="dttext">{{ ipRegion(currentRow.ipRegion) }}</span>
        </div>
        <el-divider></el-divider>
        <div class="detail">
          <span class="dtcontent">业务组</span><span class="dttext">{{ currentRow.groupName }}</span>
        </div>
        <el-divider></el-divider>
        <div class="detail">
          <span class="dtcontent">IP地址</span><span class="dttext">{{ currentRow.ip }}</span>
        </div>
        <el-divider></el-divider>
        <div class="detail">
          <span class="dtcontent">状态</span><span class="dttext">{{ lineStatus(currentRow.status) }}</span>
        </div>
        <el-divider></el-divider>
        <div class="detail">
          <span class="dtcontent">到期时间</span><span class="dttext">{{
            dealTime(currentRow.expireTime, "YYYY-MM-DD")
          }}</span>
        </div>
        <el-divider></el-divider>
        <div class="detail">
          <span class="dtcontent">自动续费</span><span class="dttext">{{ currentAutoRenew }}</span>
        </div>
        <el-divider></el-divider>
        <div v-if="!isZircon">
          <br />
          <el-select v-model="timeRange" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
          <el-button @click="refreshChart(currentRow)"> 刷新 </el-button>
          <br /><br />
          <div style="font-size: 22px">网络监控图</div>
          <br />
          <div ref="chart" style="width: 600px; height: 400px"></div>
        </div>
      </el-drawer>
    </div>
    <delete-item :title="title" canceltext="取消" confirmtext="确认" :showdelete="showdelete" @closedelete="closedelete"
      :row="row" />
    <qr-code-model ref="qrcodeview" :token="linkToken" :isqrcodeshow="isqrcodeshow"
      @closedownloadmodel="closedownloadmodel" />
    <edite-group title="修改分组" :row="row" :showeditegroup="showeditegroup" @closeeditegroup="closeeditegroup" />
    <edite-name title="修改名称" :row="row" :showeditename="showeditename" @closeeditename="closeeditename" />
    <auto-renew title="自动续费管理" :row="row" :showAutoRenew="showAutoRenew" @closeAutoRenew="closeAutoRenew" />
    <upgrade title="规格升级" :row="row" :showUpgrade="showUpgrade" :avaliableUpgradeMap="avaliableUpgradeMap"
      @closeUpgrade="closeUpgrade" />
    <setting-list title="自定义列表" :data-arr="lineAllList" :check-list="checkProp" :default-arr="DefaultPropertyArrForManage"
      :showsettinglist="showsettinglist" @closesettinglist="closesettinglist" @confirmedit="confirmedit" />
  </div>
</template>
<script>
import ComTable from "@/components/Table";
import DeleteItem from "./Models/DeleteItem";
import navigation from "@/utils/navigation";
import { getToken } from "@/utils/auth";
import QrCodeModel from "@/components/QrCodeModel";
import EditeGroup from "./Models/EditeGroup";
import EditeName from "./Models/EditeName";
import AutoRenew from "./Models/AutoRenew";
import Upgrade from "./Models/Upgrade";
import SettingList from "./Models/SettingList";
import { IpRegionMap, ProductTypeMap, LineStatusMap, isZirconLine, AutoRenewMap } from "@/utils/constants";
import formatdate from "@/utils/format";
import {groupBy} from '@/utils/tool'
import { h, ref } from "vue";
import { mapGetters } from "vuex";
import { userinfo } from '@/api/user';

const clipboard = require("clipboardy");

export default {
  name: "linelist",
  computed: {
    ...mapGetters(["subAccount"]),
    ...mapGetters(["userId"]),
    isZircon() {
      return isZirconLine(this.currentRow.productId)
    }
  },
  data () {
    return {
      myChart: null,
      myDelayChart: null,
      timeRange: "3600",
      options: [
        {
          value: "3600",
          label: "1小时",
        },
        {
          value: "21600",
          label: "6小时",
        },
        {
          value: "86400",
          label: "1天",
        },
        {
          value: "259200",
          label: "3天",
        },
      ],
      drawer: false,
      isSearch: false,
      query: "",
      headercellstyle: {
        background: "rgba(228, 229, 241)",
        color: "rgba(102,102,102)",
      },
      tabledata: [],
      lineAllList: [
        {
          prop: "name",
          label: "名称",
          width: "120",
          align: "left",
          headerAlign: "left",
          selection: false,
          index: false,
          expand: false,
          formatter: (row, column, cellValue, index) => {
            let a = {};
            if (row.isShowBtn) {
              a = {
                class: "icon-edit-name iconfont icon-bianji",
                style: { "margin-left": "6px", cursor: "pointer" },
                on: {
                  click: () => {
                    this.editename(row);
                  },
                },
              };
            }
            return h("div", [cellValue, h("span", a)]);
          },
        },
        {
          prop: "id",
          label: "资源ID",
          width: "120",
          align: "left",
          headerAlign: "left",
          selection: false,
          index: false,
          expand: false,
        },
        {
          prop: "productId",
          label: "规格",
          width: "120",
          align: "left",
          headerAlign: "left",
          selection: false,
          index: false,
          expand: false,
          formatter: (row, column, cellValue, index) => {
            return ProductTypeMap[cellValue] || cellValue;
          },
        },
        {
          prop: "groupName",
          label: "业务组",
          width: "120",
          align: "left",
          headerAlign: "left",
          selection: false,
          index: false,
          expand: false,
          formatter: (row, column, cellValue, index) => {
            let a = {};
            if (row.isShowBtn) {
              a = {
                class: "icon-edit-name iconfont icon-bianji",
                style: { "margin-left": "6px", cursor: "pointer" },
                on: {
                  click: () => {
                    this.editegroup(row);
                  },
                },
              };
            }
            return h("div", [cellValue, h("span", a)]);
          },
        },
        {
          prop: "ipRegion",
          label: "加速区域",
          width: "120",
          align: "left",
          headerAlign: "left",
          selection: false,
          index: false,
          expand: false,
          formatter: (row, column, cellValue, index) => {
            return IpRegionMap[cellValue] || cellValue;
          },
        },
        {
          prop: "ip",
          label: "IP地址",
          width: "120",
          align: "left",
          headerAlign: "left",
          selection: false,
          index: false,
          expand: false,
        },
        {
          prop: "status",
          label: "状态",
          width: "100",
          align: "left",
          headerAlign: "left",
          selection: false,
          index: false,
          expand: false,
          formatter: (row, column, cellValue, index) => {
            return LineStatusMap[cellValue] || cellValue;
          },
        },
        {
          prop: "autoRenew",
          label: "自动续费",
          width: "100",
          align: "left",
          headerAlign: "left",
          selection: false,
          index: false,
          expand: false,
          formatter: (row, column, cellValue, index) => {
            return AutoRenewMap[cellValue] || cellValue;
          },
        },
        {
          prop: "createTime",
          label: "创建时间",
          width: "135",
          align: "left",
          headerAlign: "left",
          selection: false,
          index: false,
          expand: false,
          formatter: (row, column, cellValue, index) => {
            return formatdate(cellValue, "YYYY-MM-DD");
          },
        },
        {
          prop: "expireTime",
          label: "到期时间",
          width: "135",
          align: "left",
          headerAlign: "left",
          selection: false,
          index: false,
          expand: false,
          formatter: (row, column, cellValue, index) => {
            return formatdate(cellValue, "YYYY-MM-DD");
          },
        },
      ],
      lineList: [
        {
          width: "30",
          align: "left",
          selection: true,
          reserve: true,
        },
        {
          prop: "name",
          label: "名称",
          width: "120",
          align: "left",
          headerAlign: "left",
          selection: false,
          index: false,
          expand: false,
          formatter: (row, column, cellValue, index) => {
            let a = {};
            if (row.isShowBtn) {
              a = {
                class: "icon-edit-name iconfont icon-bianji",
                style: { "margin-left": "6px", cursor: "pointer" },
                on: {
                  click: () => {
                    this.editename(row);
                  },
                },
              };
            }
            return h("div", [cellValue, h("span", a)]);
          },
        },
        {
          prop: "id",
          label: "资源ID",
          width: "120",
          align: "left",
          headerAlign: "left",
          selection: false,
          index: false,
          expand: false,
        },
        {
          prop: "productId",
          label: "规格",
          width: "120",
          align: "left",
          headerAlign: "left",
          selection: false,
          index: false,
          expand: false,
          formatter: (row, column, cellValue, index) => {
            return ProductTypeMap[cellValue] || cellValue;
          },
        },
        {
          prop: "groupName",
          label: "业务组",
          width: "120",
          align: "left",
          headerAlign: "left",
          selection: false,
          index: false,
          expand: false,
          formatter: (row, column, cellValue, index) => {
            let a = {};
            if (row.isShowBtn) {
              a = {
                class: "icon-edit-name iconfont icon-bianji",
                style: { "margin-left": "6px", cursor: "pointer" },
                on: {
                  click: () => {
                    this.editegroup(row);
                  },
                },
              };
            }
            return h("div", [cellValue, h("span", a)]);
          },
        },
        {
          prop: "ipRegion",
          label: "加速区域",
          width: "120",
          align: "left",
          headerAlign: "left",
          selection: false,
          index: false,
          expand: false,
          formatter: (row, column, cellValue, index) => {
            return IpRegionMap[cellValue] || cellValue;
          },
        },
        {
          prop: "ip",
          label: "IP地址",
          width: "120",
          align: "left",
          headerAlign: "left",
          selection: false,
          index: false,
          expand: false,
        },
        {
          prop: "status",
          label: "状态",
          width: "100",
          align: "left",
          headerAlign: "left",
          selection: false,
          index: false,
          expand: false,
          formatter: (row, column, cellValue, index) => {
            return LineStatusMap[cellValue] || cellValue;
          },
        },
        {
          prop: "autoRenew",
          label: "自动续费",
          width: "100",
          align: "left",
          headerAlign: "left",
          selection: false,
          index: false,
          expand: false,
          formatter: (row, column, cellValue, index) => {
            return AutoRenewMap[cellValue] || cellValue;
          },
        },
        {
          prop: "expireTime",
          label: "到期时间",
          width: "135",
          align: "left",
          headerAlign: "left",
          selection: false,
          index: false,
          expand: false,
          formatter: (row, column, cellValue, index) => {
            return formatdate(cellValue, "YYYY-MM-DD");
          },
        },
      ],
      pagination: {
        current: 1,
        size: 20,
        total: 1,
      },
      showdelete: false,
      row: {},
      title: "删除云手机",
      linkToken: "",
      isqrcodeshow: false,
      linelistdata: [],
      grouplist: [],
      dataListSelections: [],
      showeditegroup: false,
      showeditename: false,
      showAutoRenew: false,
      showsettinglist: false,
      showUpgrade: false,
      checking: false,
      metricslist: [],
      metricslistx: [],
      metricslisty: [],
      metricsdelaylist: [],
      metricsdelaylistx: [],
      metricsdelaylisty: [],
      currentId: "",
      currentRow: {},
      currentAutoRenew: "",
      showTableColumn: [
        {
          width: "30",
          align: "left",
          selection: true,
          reserve: true,
        },
        {
          prop: "name",
          label: "名称",
          width: "120",
          align: "left",
          headerAlign: "left",
          selection: false,
          index: false,
          expand: false,
          formatter: (row, column, cellValue, index) => {
            let a = {};
            if (row.isShowBtn) {
              a = {
                class: "icon-edit-name iconfont icon-bianji",
                style: { "margin-left": "6px", cursor: "pointer" },
                on: {
                  click: () => {
                    this.editename(row);
                  },
                },
              };
            }
            return h("div", [cellValue, h("span", a)]);
          },
        },
        {
          prop: "id",
          label: "资源ID",
          width: "140",
          align: "left",
          headerAlign: "left",
          selection: false,
          index: false,
          expand: false,
          formatter: (row, column, cellValue, index) => {
            const style = this.rowStyle({row})
            style.padding = '2px 3px'
            return h('span', {style}, cellValue);
          },
        },
        {
          prop: "productId",
          label: "规格",
          width: "120",
          align: "left",
          headerAlign: "left",
          selection: false,
          index: false,
          expand: false,
          formatter: (row, column, cellValue, index) => {
            return ProductTypeMap[cellValue] || cellValue;
          },
        },
        {
          prop: "groupName",
          label: "业务组",
          width: "120",
          align: "left",
          headerAlign: "left",
          selection: false,
          index: false,
          expand: false,
          formatter: (row, column, cellValue, index) => {
            let a = {};
            if (row.isShowBtn) {
              a = {
                class: "icon-edit-name iconfont icon-bianji",
                style: { "margin-left": "6px", cursor: "pointer" },
                on: {
                  click: () => {
                    this.editegroup(row);
                  },
                },
              };
            }
            return h("div", [cellValue, h("span", a)]);
          },
        },
        {
          prop: "ipRegion",
          label: "加速区域",
          width: "120",
          align: "left",
          headerAlign: "left",
          selection: false,
          index: false,
          expand: false,
          formatter: (row, column, cellValue, index) => {
            return IpRegionMap[cellValue] || cellValue;
          },
        },
        {
          prop: "ip",
          label: "IP地址",
          width: "120",
          align: "left",
          headerAlign: "left",
          selection: false,
          index: false,
          expand: false,
        },
        {
          prop: "status",
          label: "状态",
          width: "100",
          align: "left",
          headerAlign: "left",
          selection: false,
          index: false,
          expand: false,
          formatter: (row, column, cellValue, index) => {
            return LineStatusMap[cellValue] || cellValue;
          },
        },
        {
          prop: "autoRenew",
          label: "自动续费",
          width: "100",
          align: "left",
          headerAlign: "left",
          selection: false,
          index: false,
          expand: false,
          formatter: (row, column, cellValue, index) => {
            return AutoRenewMap[cellValue] || cellValue;
          },
        },
        {
          prop: "expireTime",
          label: "到期时间",
          width: "135",
          align: "left",
          headerAlign: "left",
          selection: false,
          index: false,
          expand: false,
          sortable: true,
          formatter: (row, column, cellValue, index) => {
            const style = this.rowStyle({row})
            const timeStr = formatdate(cellValue, "YYYY-MM-DD")
            return h('span', {style}, timeStr);
          },
        },
      ],
      checkProp: [], // 选中字段
      DefaultPropertyArrForManage: [
        "名称",
        "资源ID",
        "规格",
        "加速区域",
        "业务组",
        "IP地址",
        "状态",
        "自动续费",
        "到期时间",
      ], // 默认选中字段
      optionss: {
        title: {
          text: "出向峰值带宽（Mbps）",
          subtext: "",
        },

        tooltip: {
          trigger: "axis",
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: this.metricslistx,
        },
        yAxis: {
          type: "value",
          axisLabel: {
            formatter: "{value}",
          },
        },

        series: [
          {
            symbol: "circle", //是否显示实心的折线圆点
            symbolSize: 0, //折线圆点的大小
            type: "line",
            data: this.metricslisty,
            itemStyle: {
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: " #2A82E4", // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: "#ffffff", // 100% 处的颜色
                  },
                ],
                global: false, // 缺省为 false
              },
            },
            lineStyle: {
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 1,
                y2: 1,
                colorStops: [
                  {
                    offset: 0.5,
                    color: " #2A82E4", // 0% 处的颜色
                  },
                ],
                global: false, // 缺省为 false
              },
            },
            areaStyle: {},
          },
        ],
        brush: {
          yAxisIndex: "all",
          brushType: "lineX",
          brushMode: "single",
        },
        toolbox: {
          show: false,
        },
      },
      delayoptionss: {
        title: {
          text: "（ms）",
          subtext: "",
        },

        tooltip: {
          trigger: "axis",
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: this.metricsdelaylistx,
        },
        yAxis: {
          type: "value",
          axisLabel: {
            formatter: "{value}",
          },
        },

        series: [
          {
            symbol: "circle", //是否显示实心的折线圆点
            symbolSize: 0, //折线圆点的大小
            type: "line",
            data: this.metricsdelaylisty,
            itemStyle: {
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: " #2A82E4", // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: "#ffffff", // 100% 处的颜色
                  },
                ],
                global: false, // 缺省为 false
              },
            },
            lineStyle: {
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 1,
                y2: 1,
                colorStops: [
                  {
                    offset: 0.5,
                    color: " #2A82E4", // 0% 处的颜色
                  },
                ],
                global: false, // 缺省为 false
              },
            },
            areaStyle: {},
          },
        ],
        brush: {
          yAxisIndex: "all",
          brushType: "lineX",
          brushMode: "single",
        },
        toolbox: {
          show: false,
        },
      },
      connectList: [
        {
          prop: "connectTime",
          label: "连接时间",
          width: "120",
          align: "center",
          headerAlign: "center",
          selection: false,
          index: false,
          expand: false,
          formatter: (row, column, cellValue, index) => {
            return formatdate(cellValue, "YYYY-MM-DD HH:MM:SS");
          },
        },
        {
          prop: "connectSource",
          label: "连接设备",
          width: "120",
          align: "center",
          headerAlign: "center",
          selection: false,
          index: false,
          expand: false,
        },
      ],
      connecttabledata: [],
      pingData: "",
      isShow: false,
      avaliableUpgradeMap: new Map(),
    };
  },
  components: {
    ComTable,
    DeleteItem,
    QrCodeModel,
    EditeGroup,
    EditeName,
    AutoRenew,
    SettingList,
    Upgrade,
  },
  watch: {
    timeRange: {
      handler: function (val) {
        this.refreshChart();
      },
    },
  },
  methods: {
    showDetail (row) {
      this.currentId = row.id;
      this.currentRow = row;
      this.drawer = true;
      if (row.autoRenew == 0) {
        this.currentAutoRenew = "未开启";
      } else if (row.autoRenew == 7) {
        this.currentAutoRenew = "已开启：按7天";
      } else if (row.autoRenew == 30) {
        this.currentAutoRenew = "已开启：按月";
      } else if (row.autoRenew == 90) {
        this.currentAutoRenew = "已开启：按季度";
      } else if (row.autoRenew == 180) {
        this.currentAutoRenew = "已开启：按半年";
      } else if (row.autoRenew == 360) {
        this.currentAutoRenew = "已开启：按年";
      }
      this.getpingdetail();
      this.getconnectdetail();
      this.myDelayChart = null;
      this.myChart = null;
      if (!this.myChart) {
        this.$nextTick(() => {
          this.myChart = this.$echarts.init(this.$refs.chart);
          this.myChart.on("brushEnd", this.brushEnd);
          this.drawChart();
        });
      }

      if (!this.myDelayChart) {
        this.$nextTick(() => {
          this.myDelayChart = this.$echarts.init(this.$refs.delayChart);
          this.myDelayChart.on("brushEnd", this.brushDelayEnd);
          this.drawDelayChart();
        });
      }
    },

    brushEnd (params) {
      // console.log(params);
      let range = params.areas?.[0]?.coordRange;
      if (range && range.length == 2) {
        if (!this.metricslist?.[range[0]] || !this.metricslist?.[range[1]]) {
          return;
        }
        let beginTime = this.metricslist?.[range[0]].timestamp;
        let endTime = this.metricslist?.[range[1]].timestamp;
        this.drawChart(beginTime, endTime);
      }
    },
    refreshChart (beginTime = 0, endTime = 0) {
      this.drawChart();
    },
    drawChart (beginTime = 0, endTime = 0) {
      let data = {
        id: this.currentId,
      };
      if (beginTime != 0 && endTime != 0) {
        data.beginTime = beginTime;
        data.endTime = endTime;
      } else {
        data.timeRange = this.timeRange;
      }
      this.myChart.showLoading();
      this.$store.dispatch("line/setlineipmetrics", { ...data }).then((res) => {
        this.myChart.hideLoading();
        this.metricslisty = [];
        this.metricslistx = [];
        if (res.retCode == 0) {
          this.metricslist = res.metrics;
          this.metricslist.forEach((item1, index, arr) => {
            this.metricslisty.push(
              (res.metrics[index].value / 1000000).toFixed(5)
            );
            this.metricslistx.push(
              formatdate(res.metrics[index].timestamp, "MM-DD HH:MM:SS")
            );
          });
          this.optionss.xAxis.data = this.metricslistx;
          this.optionss.series[0].data = this.metricslisty;

          this.myChart.setOption(this.optionss);

          this.myChart.dispatchAction({
            type: "brush",
            areas: [],
          });
          this.myChart.dispatchAction({
            type: "takeGlobalCursor",
            key: "brush",
            brushOption: {
              brushType: "lineX",
              brushMode: "single",
            },
          });
        }
      });
    },

    navtocreate () {
      navigation.navigateTo({
        url: "createline",
        params: {},
      });
    },
    pathtool (row) {
      navigation.navigateTo({
        url: "linetool",
        params: {
          row: {
            ...row,
          },
        },
      });
    },
    batchrenew () {
      if (Object.entries(this.dataListSelections).length == 0) {
        this.$message.warning("至少需选择一个项目");
        return;
      }
      // console.log(...this.dataListSelections)
      navigation.navigateTo({
        url: "linerenewconfirm",
        params: {
          payload: {
            ...this.dataListSelections,
          },
        },
      });
    },
    gorenew (item) {
      let renewform = [{
        product_type: "Path",
        ipRegion: item.ipRegion,
        productId: item.productId,
        id: item.id,
        name: item.name,
        groupName: item.groupName,
        status: item.status,
      }]
      navigation.navigateTo({
        url: "linerenewconfirm",
        params: {
          payload: {
            ...renewform
          },
        },
      });
    },
    refreshlist () {
      this.getgrouplist();
    },
    getconnectdetail () {
      let data = {
        id: this.currentId,
      };
      this.connecttabledata = [];
      this.$store
        .dispatch("line/getconnetctdetails", { ...data })
        .then((res) => {
          // console.log("get connectdetail response: " + JSON.stringify(res));
          if (res.retCode == 0) {
            this.connecttabledata = res.connectData;
          }
        });
    },
    getpingdetail () {
      let data = {
        id: this.currentId,
      };
      this.connecttabledata = [];
      this.$store.dispatch("line/getpingdetails", { ...data }).then((res) => {
        // console.log("get pingdetail response: " + JSON.stringify(res));
        if (res.retCode == 0) {
          this.pingData = res.pingData;
        } else {
          this.pingData = res.message;
        }
      });
    },

    getlinelist () {
      var hasToken = getToken();
      this.$store
        .dispatch("line/getlinelist", { Authorization: hasToken })
        .then((res) => {
          // console.log("get linelist response: " + JSON.stringify(res));
          if (res.lineInfos !== null && res.lineInfos.length > 0) {
            this.linelistdata = res.lineInfos;
            this.linelistdata.forEach((item1, index, arr) => {
              this.grouplist.forEach((item2) => {
                if (item1.group == item2.Id) {
                  item1.group = item2.Name;
                }
              });
            });
            // 将临近过期的线路升序展示在最前面
            const {E: expire = [], N: normal = []} = groupBy(res.lineInfos, (line) => {
              return this.isLineNearExpire(line) ? 'E' : 'N'
            })
            if (expire.length) {
              expire.sort((a, b) => a.expireTime - b.expireTime)
              this.tabledata = [...expire, ...normal]
            } else {
              this.tabledata = res.lineInfos
            }
            for(const line of this.tabledata) {
              this.$store.dispatch("line/isprobation", {id: line.id}).then((res) => {
              line.isInWhiteList = !res.isShow; // 将权限信息赋值给组件的本地状态
              line.isExistPermission = res.permission
            }).catch((err) => {
              console.error("获取白名单权限信息时出错:", err);
              });
            }
          }
        });
      this.isSearch = false;
    },
    getgrouplist () {
      this.$store.dispatch("group/getgrouplist").then((res) => {
        // console.log("get grouplist response: " + JSON.stringify(res));
        if (res.RetCode == 0) {
          this.grouplist = res.Groups;
          this.getlinelist();
        }
      });
    }, 

    getuserinfo () {
      this.$store.dispatch("user/getuserinfo").then((res) => {
        // console.log("get user info: " + JSON.stringify(res));
        if (res.RetCode == 0) {
          // if (res.UserType == 1 || res.UserType == 2) {
          //   this.isShow = true;
          // } else {
          //   this.isShow = false;
          // }
        }
      });
    },

    handleSearch () {
      this.$store.dispatch("group/getgrouplist").then((res) => {
        // console.log("get grouplist response: " + JSON.stringify(res));
        if (res.RetCode == 0) {
          this.grouplist = res.Groups;
          if (this.query != null && this.query.length > 0) {
            this.handleLineSearch();
          } else {
            this.getlinelist();
          }
        }
      });
    },
    handleLineSearch () {
      this.tabledata = [];
      var hasToken = getToken();
      this.$store
        .dispatch("line/getlinelist", { Authorization: hasToken })
        .then((res) => {
          // console.log("get linelist response: " + JSON.stringify(res));
          if (res.lineInfos !== null && res.lineInfos.length > 0) {
            this.linelistdata = res.lineInfos;
            this.linelistdata.forEach((item1, index, arr) => {
              if (
                item1.id.includes(this.query) ||
                item1.ip.includes(this.query) ||
                item1.name.includes(this.query) ||
                item1.groupName.includes(this.query)
              ) {
                this.grouplist.forEach((item2) => {
                  if (item1.group == item2.Id) {
                    item1.group = item2.Name;
                  }
                });
                this.tabledata.push(res.lineInfos[index]);
              }
            });
          }
        });
      this.isSearch = true;
    },

    getAvaliableUpgrade () {
      this.$store.dispatch("pay/getAvaliableUpgrade").then((res) => {
        if (res.RetCode == 0) {
          for (const u of res.Upgrades) {
            if (this.avaliableUpgradeMap.has(u.ProductBefore)) {
              this.avaliableUpgradeMap.get(u.ProductBefore).push(u.ProductAfter);
            } else {
              this.avaliableUpgradeMap.set(u.ProductBefore, [u.ProductAfter]);
            }
          }
          // console.log(this.avaliableUpgradeMap)
        }
      });
    },

    closedelete () {
      this.showdelete = !this.showdelete;
    },
    showdeleteitem (row) {
      this.row = row;
      this.showdelete = !this.showdelete;
    },
    showdownloadmodel (row) {
      if (row.status == "STATUS_DUE") {
        this.$message.error("资源已过期");
        return;
      }
      this.isqrcodeshow = !this.isqrcodeshow;
      this.$refs.qrcodeview.showQrCodeView(row.id, row.ip, row.ipRegion);
      this.$refs.qrcodeview.fetchAuthority(row.id);
    },
    copyToken (row) {
      if (row.status == "STATUS_DUE") {
        this.$message.error("资源已过期");
        return;
      }
      console.log(row);
      this.$store.dispatch("line/getpathtoken", { id: row.id }).then((res) => {
        // console.log("get token response:" + JSON.stringify(res));
        if (res.token !== "") {
          clipboard.write(res.token).then(() => {
            this.$message.success("复制链接成功");
          });
        } else {
          this.$message.error("获取链接失败");
        }
      });
    },
    closedownloadmodel () {
      this.isqrcodeshow = !this.isqrcodeshow;
    },
    showAutoRenewModel (row) {
      // console.log(row);
      this.showAutoRenew = !this.showAutoRenew;
      this.row = row;
    },
    closeAutoRenew () {
      this.showAutoRenew = !this.showAutoRenew;
      this.getlinelist();
    },
    showUpgradeModel (row) {
      this.showUpgrade = !this.showUpgrade;
      this.row = row;
    },
    closeUpgrade () {
      this.showUpgrade = !this.showUpgrade;
      this.getlinelist();
    },
    editegroup (row) {
      if (this.subAccount) {
        this.$message.warning(
          "子账号暂不支持修改分组功能，请登录主账号进行操作~"
        );
        return;
      }
      // console.log("editgroup: " + JSON.stringify(row));
      this.row = row;
      this.showeditegroup = !this.showeditegroup;
    },
    closeeditegroup () {
      this.showeditegroup = !this.showeditegroup;
      this.getlinelist();
    },
    editename (row) {
      if (this.subAccount) {
        this.$message.warning(
          "子账号暂不支持修改名称功能，请登录主账号进行操作~"
        );
        return;
      }
      this.row = row;
      this.showeditename = !this.showeditename;
    },
    closeeditename () {
      this.showeditename = !this.showeditename;
      this.getlinelist();
    },
    refreshconn (row) {
      this.$confirm('重置线路会导致正在连接的设备连接失效，是否继续？', {
        title: '重置线路',
        type: 'warning',
        confirmButtonText: '确认重置'
      })
        .then(() => {
          this.$store.dispatch("line/resetline", { id: row.id }).then((res) => {
            if (res.retCode == 0) {
              this.$alert('连接重置成功, 请将当前连接线路的设备重新连接', {
                type: 'success',
              })
            } else {
              this.$message.error("连接重置失败,原因: " + res.message);
            }
          });
        })
        .catch(() => {
          this.$message.info('重置操作已取消')
        })
    },
    disconnection (row) {
      this.$confirm('试用三天之后线路将重置，是否继续？', {
        title: '线路试用',
        type: 'warning',
        confirmButtonText: '确认'
      })
        .then(() => {
          this.$store.dispatch("line/resetTrailLine", { id: row.id }).then((res) => {
            if (res.retCode == 0) {
              this.$alert('连接重置成功, 请将当前连接线路的设备重新连接', {
                type: 'success',
              })
              // 在获取权限信息后立即刷新页面
                setTimeout(function() {
                    location.reload();
                }, 1000); 
            } else {
              this.$message.error("连接重置失败,原因: " + res.message);
            }
          });
        })
        .catch(() => {
          this.$message.info('重置操作已取消')
        })
    },
    handleSelectionChange (val) {
      this.dataListSelections = [];
      for (let item of val) {
        let renewform = {
          product_type: "Path",
          ipRegion: item.ipRegion,
          productId: item.productId,
          id: item.id,
          name: item.name,
          groupName: item.groupName,
          status: item.status,
        };
        this.dataListSelections.push(renewform);
      }

    },
    checklinelist () {
      var needCheck = false;
      var hasToken = getToken();
      this.$store
        .dispatch("line/getlinelist", { Authorization: hasToken })
        .then((res) => {
          if (res.lineInfos !== null && res.lineInfos.length > 0) {
            this.linelistdata = res.lineInfos;
            res.lineInfos.forEach((item1, index, arr) => {
              this.grouplist.forEach((item2) => {
                if (item1.group == item2.Id) {
                  item1.group = item2.Name;
                }
              });
              if (item1.status != "创建成功") {
                needCheck = true;
              }
            });
            this.tabledata = res.lineInfos;
            this.checking = needCheck;
          }
        });
    },
    dealTime (time) {
      return formatdate(time, "YYYY-MM-DD");
    },
    lineStatus (status) {
      return LineStatusMap[status];
    },
    productType (productId) {
      return ProductTypeMap[productId];
    },
    ipRegion (ipRegion) {
      return IpRegionMap[ipRegion];
    },
    cellmouseenter (row) {
      row.isShowBtn = true;
      let Arr = JSON.parse(JSON.stringify(this.tabledata));
      this.tabledata = JSON.parse(JSON.stringify(Arr));
    },
    cellmouseleave (row) {
      this.tabledata = this.tabledata.map((n) => {
        n.isShowBtn = false;
        return n;
      });
      let Arr = JSON.parse(JSON.stringify(this.tabledata));
      this.tabledata = JSON.parse(JSON.stringify(Arr));
    },

    settingClick () {
      this.showsettinglist = !this.showsettinglist;
      if (this.showsettinglist) {
        this.checkProp = this.DefaultPropertyArrForManage;
        // console.log("gggggg1", this.DefaultPropertyArrForManage);
      }
    },
    closesettinglist () {
      this.showsettinglist = !this.showsettinglist;
    },
    confirmedit (val) {
      this.checkProp = val;
      this.dealTableColumn(this.checkProp);
    },
    // 重新渲染table表格
    dealTableColumn (val) {
      this.showTableColumn = [];
      this.DefaultPropertyArrForManage = [
        "名称",
        "资源ID",
        "规格",
        "加速区域",
        "业务组",
        "IP地址",
        "状态",
        "自动续费",
        "到期时间",
      ];
      this.showTableColumn.push(this.lineList[0]);
      for (let i = 0; i < this.lineAllList.length; i++) {
        for (let j = 0; j < val.length; j++) {
          if (this.lineAllList[i].label == val[j]) {
            this.showTableColumn.push(this.lineAllList[i]);
            if (
              !this.DefaultPropertyArrForManage.includes(
                this.lineAllList[i].label
              )
            ) {
              this.DefaultPropertyArrForManage.push(this.lineAllList[i].label);
            }
          }
        }
      }
    },
    rowStyle({ row }) {
      const nearExp = this.lineNearExpireRate(row)
      // [120, 200]
      const red = (1 - nearExp) * 80 + 120
      return nearExp ? {
        background: `rgb(255, ${red}, ${red})`,
        color: 'black',
        fontWeight: 'bold',
        borderRadius: '5px',
        padding: '2px 10px'
      } : {}
    },
    lineNearExpireRate(row) {
      // 自动续费
      const autoRenew = row.autoRenew > 0
      if (autoRenew) {
        return 0
      }
      const expTime = row.expireTime * 1000
      const now = new Date().getTime()
      const sevenDays = 7 * 24 * 60 * 60 * 1000
      if (expTime - now > sevenDays) {
        return 0
      }
      // rate = 1 - t / T
      return 1 - ((expTime - now) / sevenDays)
    },
    isLineNearExpire(row) {
      return this.lineNearExpireRate(row) > 0
    }
  },
  mounted () {
    this.getgrouplist();
    this.getuserinfo();
    this.getAvaliableUpgrade();
  },
  computed: {
    ...mapGetters(["subAccount"]),
    isZircon() {
      return isZirconLine(this.currentRow.productId)
    },
  },
  beforeRouteEnter (to, from, next) {
    next((vm) => {
      let counter = 0;
      vm.checking = true;
      vm.fullPath = from.name;
      // console.log("route name: " + vm.fullPath);
      if (vm.fullPath == "lineorderconfirm" || vm.fullPath == 'upgradeorderconfirm') {
        let timer = setInterval(() => {
          counter++;
          if (counter > 10) {
            clearInterval(timer);
          }
          vm.checklinelist();
        }, 3000);
      }
    });
  },
};
</script>
<style lang="scss" scoped>
@import "./index.scss";

::v-deep .el-drawer__body {
  padding: 20px;
  padding-top: 0px;
}
</style>